import React, { useContext, useState } from "react";
import logopromo from "../../assets/promolife/LOGO.png";
import { motion } from "framer-motion";
import { FirebaseFuntions } from "../../context/FirebaseContext";
import { DatePicker, TimePicker, message } from "antd";
import es from "antd/es/date-picker/locale/es_ES";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Input } from "antd";

const BookAppointment = () => {
  const { createCited } = useContext(FirebaseFuntions);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const { userId, projectId } = useParams();
  const navigate = useNavigate();
  const { TextArea } = Input;

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Array.from(formData.entries()).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    if (date && time) {
      const selectedDate = date.toDate();  // Convert Moment date to JavaScript Date
      const selectedTime = time.toDate();  // Convert Moment time to JavaScript Date

      const combinedDateTime = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate(),
        selectedTime.getHours(),
        selectedTime.getMinutes(),
        selectedTime.getSeconds()
      );

      const timestamp = combinedDateTime.getTime();
      data.timestamp = timestamp;
      message.success("Cita agendada correctamente");
      navigate(`/${projectId}/${userId}`);
    } else {
      message.error("Fecha u hora no seleccionada");
      return;
    }
    createCited({ ...data, userId: userId, projectId: projectId });
  };

  return (
    <div className="flex flex-col md:flex-row items-center justify-center px-8 py-8 mx-auto min-h-screen bg-white">
      <div className="w-full md:w-1/2 flex items-center justify-center mb-8 md:mb-0">
        <motion.img initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeInOut" }}
          src={logopromo} alt="Imagen" className="w-72 md:w-96" />
      </div>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: "easeInOut" }}
        className="w-full md:w-1/2 bg-black rounded-lg md:mt-0 sm:max-w-md">
        <div className="p-6 sm:p-6">
          <h1 className="text-2xl font-bold text-white mb-5">AGENDAR CITA</h1>
          <form onSubmit={handleSubmit} className="flex flex-col">
            <Input
              className="mb-5 p-2"
              id="name"
              name="name"
              type="text"
              placeholder="Nombre completo"
              required
            />
            <Input
              className="mb-5 p-2"
              id="mail"
              name="mail"
              type="email"
              placeholder="Correo electrónico"
              required
            />
            <Input
              className="mb-5 p-2"
              id="phone"
              name="phone"
              type="tel"
              placeholder="Teléfono"
              required
            />
            <TextArea
              className="mb-5"
              id="message"
              name="message"
              rows={4}
              placeholder="Mensaje"
            />
            <div className="flex justify-between">
              <DatePicker
                className="p-2"
                locale={es}
                placeholder="Fecha"
                onChange={(date) => setDate(date)}
                disabledDate={(current) => current && current.valueOf() < Date.now() - 86400000}
                size="middle"
              />
              <TimePicker
                className="p-2"
                locale={es}
                placeholder="Hora"
                onChange={(time) => setTime(time)}
                size="middle"
                showNow={false}
                format="HH:mm"
                disabledTime={() => { return { disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 17, 18, 19, 20, 21, 22, 23] } }
                }
              />
            </div>
            <motion.button
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", stiffness: 400, damping: 17 }}
              className="bg-makinn_primary text-white font-bold py-2 px-4 rounded-lg uppercase focus:outline-none font-body mt-5"
              type="submit"
            >
              Ingresar
            </motion.button>
          </form>
        </div>
      </motion.div>
    </div>
  );
};

export default BookAppointment;
