import React, { useContext, useEffect, useMemo, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import Header from "../../components/Header/Header"
import HeaderL from "../../templates/luxury/Header/Header"
import HeaderBH from "../../templates/bh/Header/Header"
import HeaderLoginBh from "../../templates/bh/HeaderLogin/HeaderLogin"
import logo from "../../assets/makinn/makinn.png"
import HeaderLogin from "../../components/HeaderLogin/HeaderLogin"
import { FirebaseFuntions } from "../../context/FirebaseContext"
import MainCointainer from "../../components/MainCointainer/MainCointainer"
import MainCointainerL from "../../templates/luxury/MainCointainer/MainCointainer"
import MainCointainerBH from "../../templates/bh/MainCointainer/MainCointainer"
import { motion, AnimatePresence } from "framer-motion"
import HeaderWen from "../../templates/wen/Header/Header"
import MainCointainerWen from "../../templates/wen/MainCointainer/MainCointainer"
import HeaderLoginWen from "../../templates/wen/HeaderLogin/HeaderLogin"
import HeaderVoestalpine from "../../templates/voestalpine/Header/Header"
import MainCointainerVoestalpine from "../../templates/voestalpine/MainCointainer/MainCointainer"
import HeaderLoginVoestalpine from "../../templates/voestalpine/HeaderLogin/HeaderLogin"
import HeaderCarnot from "../../templates/carnot/Header/Header"
import MainCointainerCarnot from "../../templates/carnot/MainCointainer/MainCointainer"
import HeaderLoginCarnot from "../../templates/carnot/HeaderLogin/HeaderLogin"
import HeaderProcaps from "../../templates/procaps/Header/Header"
import MainCointainerProcaps from "../../templates/procaps/MainCointainer/MainCointainer"
import HeaderLoginProcaps from "../../templates/procaps/HeaderLogin/HeaderLogin"
import HeaderOllamani from "../../templates/ollamani/Header/Header"
import MainCointainerOllamani from "../../templates/ollamani/MainCointainer/MainCointainer"
import HeaderLoginOllamani from "../../templates/ollamani/HeaderLogin/HeaderLogin"
import HeaderOxxo from "../../templates/oxxo/Header/Header"
import HeaderLoginOxxo from "../../templates/oxxo/HeaderLogin/HeaderLogin"
import MainCointainerOxxo from "../../templates/oxxo/MainCointainer/MainCointainer"


const applinks = {
    android: "https://play.google.com/store/apps/details?id=com.gnp&hl=es_MX&gl=US",
    web: "https://www.gnp.com.mx/soy-cliente",
    ios: "https://apps.apple.com/mx/app/soy-cliente-gnp/id540222216"
}

const Dashboard = () => {
    const { loginFlag, searchUser, authStatus, authID, projectPhoto } = useContext(FirebaseFuntions);
    const { userId } = useParams()
    const { projectId } = useParams()
    const [user, setUser] = useState("")
    const createAccount = useNavigate()
    const [device, setDevice] = useState("")
    const [typeOfDevice, setTypeOfDevice] = useState("")
    const [loader, setLoader] = useState(false)
    const [projectLogo, setProjectLogo] = useState({})

    useMemo(() => {
        if (userId || projectId) {
            const data = searchUser(userId, projectId);
            data.then((res) => {
                setUser(res);
            });
        }
    }, [userId, projectId, searchUser]);

    useEffect(() => {
        if (user === null) {
            createAccount(`/${projectId}/create/${userId}`);
        }
        if (projectId === 'carnot') {
            window.location.href = 'https://carnort.makinntap.com/';
            return;
        }
        /*if (!localStorage.getItem("projectLogo")) {
            const respuesta = projectPhoto(projectId)
            respuesta.then((res) => {
                setProjectLogo(res)
                localStorage.setItem("projectLogo", JSON.stringify(res))
            })
        }*/
        if (true) {
            const respuesta = projectPhoto(projectId)
            respuesta.then((res) => {
                setProjectLogo(res)
                localStorage.setItem("projectLogo", JSON.stringify(res))
            })
        }
        //save poject logo in cookie
        /*if (localStorage.getItem("projectLogo")) {
            const data = JSON.parse(localStorage.getItem("projectLogo"))
            setProjectLogo(data)
            console.log("logo", data)
        }*/
        authStatus();
        if (/android/i.test(navigator.userAgent)) {
            setDevice(applinks.android);
            setTypeOfDevice("Android");
        } else if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
            setDevice(applinks.ios);
            setTypeOfDevice("iOS");
        } else {
            setDevice(applinks.web);
            setTypeOfDevice("Web");
        }
        handleLoader();
    }, [user, createAccount, userId, projectId, authStatus, device, authID, projectPhoto]);

    const handleLoader = () => {
        setTimeout(() => {
            setLoader(true)
        }, 3500)
    }

    if (!user?.template || user?.template === null || user?.template === "promo") {
        return (
            <>

                {loginFlag ? (
                    loader && user ? (
                        authID === user.authID ? (
                            <>
                                <Header
                                    props={{
                                        userId,
                                        projectId
                                    }}
                                />
                                <MainCointainer
                                    user={user}
                                    device={device}
                                    typeOfDevice={typeOfDevice}
                                    projectLogo={projectLogo}
                                />
                            </>
                        ) : (
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <div>
                                    <h1 className="uppercase font-bold font-heading">No tienes permiso para ver otro perfil hasta cerrar sesión</h1>
                                </div>
                            </div>
                        )
                    ) : (
                        <AnimatePresence>
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <motion.img
                                    initial={{ rotateY: 0 }}
                                    animate={{ rotateY: 360 }}
                                    transition={{ duration: 2, repeat: Infinity, type: "tween", repeatDelay: 0 }}
                                    exit={{ opacity: 0 }}
                                    src={logo}
                                    alt="logo makinn"
                                    className="mx-auto mb-5"
                                    width={150} height={150}
                                />
                                <p className="uppercase text-lg">Cargando</p>
                            </div>
                        </AnimatePresence>
                    )
                ) : (

                    loader && user ? (
                        <>
                            <HeaderLogin
                                user={user}
                            />
                            <MainCointainer
                                user={user}
                                device={device}
                                typeOfDevice={typeOfDevice}
                                projectLogo={projectLogo}
                            />
                        </>
                    ) : (
                        <AnimatePresence>
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <motion.img
                                    initial={{ rotateY: 0 }}
                                    animate={{ rotateY: 360 }}
                                    transition={{ duration: 2, repeat: Infinity, type: "tween", repeatDelay: 0 }}
                                    exit={{ opacity: 0 }}
                                    src={logo}
                                    alt="logo makinn"
                                    className="mx-auto mb-5"
                                    width={150} height={150}
                                />
                                <p className="uppercase text-lg">Cargando</p>
                            </div>
                        </AnimatePresence>
                    )
                )
                }

            </>
        )
    }
    if (user?.template === "luxury") {
        return (
            <>
                {loginFlag ? (
                    loader && user ? (
                        authID === user.authID ? (
                            <>
                                <HeaderL
                                    props={{
                                        userId,
                                        projectId
                                    }}
                                />
                                <MainCointainerL
                                    loginFlag={loginFlag}
                                    user={user}
                                    device={device}
                                    typeOfDevice={typeOfDevice}
                                />
                            </>
                        ) : (
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <div>
                                    <h1 className="uppercase font-bold font-heading">No tienes permiso para ver otro perfil hasta cerrar sesión</h1>
                                </div>
                            </div>
                        )
                    ) : (
                        <AnimatePresence>
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <motion.img
                                    initial={{ rotateY: 0 }}
                                    animate={{ rotateY: 360 }}
                                    transition={{ duration: 2, repeat: Infinity, type: "tween", repeatDelay: 0 }}
                                    exit={{ opacity: 0 }}
                                    src={logo}
                                    alt="logo makinn"
                                    className="mx-auto mb-5"
                                    width={150} height={150}
                                />
                                <p className="uppercase text-lg">Cargando</p>
                            </div>
                        </AnimatePresence>
                    )
                ) : (
                    loader && user ? (
                        <>
                            <MainCointainerL
                                loginFlag={loginFlag}
                                user={user}
                                device={device}
                                typeOfDevice={typeOfDevice}
                            />
                        </>
                    ) : (
                        <AnimatePresence>
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <motion.img
                                    initial={{ rotateY: 0 }}
                                    animate={{ rotateY: 360 }}
                                    transition={{ duration: 2, repeat: Infinity, type: "tween", repeatDelay: 0 }}
                                    exit={{ opacity: 0 }}
                                    src={logo}
                                    alt="logo makinn"
                                    className="mx-auto mb-5"
                                    width={150} height={150}
                                />
                                <p className="uppercase text-lg">Cargando</p>
                            </div>
                        </AnimatePresence>
                    )
                )
                }

            </>
        )
    }
    if (user?.template === "bh") {
        return (
            <>
                {loginFlag ? (
                    loader && user ? (
                        authID === user.authID ? (
                            <>
                                <HeaderBH
                                    props={{
                                        userId,
                                        projectId
                                    }}
                                />
                                <MainCointainerBH
                                    loginFlag={loginFlag}
                                    user={user}
                                    device={device}
                                    typeOfDevice={typeOfDevice}
                                />
                            </>
                        ) : (
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <div>
                                    <h1 className="uppercase font-bold font-heading">No tienes permiso para ver otro perfil hasta cerrar sesión</h1>
                                </div>
                            </div>
                        )
                    ) : (
                        <AnimatePresence>
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <motion.img
                                    initial={{ rotateY: 0 }}
                                    animate={{ rotateY: 360 }}
                                    transition={{ duration: 2, repeat: Infinity, type: "tween", repeatDelay: 0 }}
                                    exit={{ opacity: 0 }}
                                    src={logo}
                                    alt="logo makinn"
                                    className="mx-auto mb-5"
                                    width={150} height={150}
                                />
                                <p className="uppercase text-lg">Cargando</p>
                            </div>
                        </AnimatePresence>
                    )
                ) : (
                    loader && user ? (
                        <>
                            <HeaderLoginBh
                                user={user}
                            />
                            <MainCointainerBH
                                loginFlag={loginFlag}
                                user={user}
                                device={device}
                                typeOfDevice={typeOfDevice}
                            />
                        </>
                    ) : (
                        <AnimatePresence>
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <motion.img
                                    initial={{ rotateY: 0 }}
                                    animate={{ rotateY: 360 }}
                                    transition={{ duration: 2, repeat: Infinity, type: "tween", repeatDelay: 0 }}
                                    exit={{ opacity: 0 }}
                                    src={logo}
                                    alt="logo makinn"
                                    className="mx-auto mb-5"
                                    width={150} height={150}
                                />
                                <p className="uppercase text-lg">Cargando</p>
                            </div>
                        </AnimatePresence>
                    )
                )
                }

            </>
        )
    }
    if (user?.template === "wen") {
        return (
            <>
                {loginFlag ? (
                    loader && user ? (
                        authID === user.authID ? (
                            <>
                                <HeaderWen
                                    props={{
                                        userId,
                                        projectId
                                    }}
                                />
                                <MainCointainerWen
                                    loginFlag={loginFlag}
                                    user={user}
                                    device={device}
                                    typeOfDevice={typeOfDevice}
                                />
                            </>
                        ) : (
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <div>
                                    <h1 className="uppercase font-bold font-heading">No tienes permiso para ver otro perfil hasta cerrar sesión</h1>
                                </div>
                            </div>
                        )
                    ) : (
                        <AnimatePresence>
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <motion.img
                                    initial={{ rotateY: 0 }}
                                    animate={{ rotateY: 360 }}
                                    transition={{ duration: 2, repeat: Infinity, type: "tween", repeatDelay: 0 }}
                                    exit={{ opacity: 0 }}
                                    src={logo}
                                    alt="logo makinn"
                                    className="mx-auto mb-5"
                                    width={150} height={150}
                                />
                                <p className="uppercase text-lg">Cargando</p>
                            </div>
                        </AnimatePresence>
                    )
                ) : (
                    loader && user ? (
                        <>
                            <HeaderLoginWen
                                user={user}
                            />
                            <MainCointainerWen
                                loginFlag={loginFlag}
                                user={user}
                                device={device}
                                typeOfDevice={typeOfDevice}
                            />
                        </>
                    ) : (
                        <AnimatePresence>
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <motion.img
                                    initial={{ rotateY: 0 }}
                                    animate={{ rotateY: 360 }}
                                    transition={{ duration: 2, repeat: Infinity, type: "tween", repeatDelay: 0 }}
                                    exit={{ opacity: 0 }}
                                    src={logo}
                                    alt="logo makinn"
                                    className="mx-auto mb-5"
                                    width={150} height={150}
                                />
                                <p className="uppercase text-lg">Cargando</p>
                            </div>
                        </AnimatePresence>
                    )
                )
                }

            </>
        )
    }
    if (user?.template === "voestalpine") {
        return (
            <>
                {loginFlag ? (
                    loader && user ? (
                        authID === user.authID ? (
                            <>
                                <HeaderVoestalpine
                                    props={{
                                        userId,
                                        projectId
                                    }}
                                />
                                <MainCointainerVoestalpine
                                    loginFlag={loginFlag}
                                    user={user}
                                    device={device}
                                    typeOfDevice={typeOfDevice}
                                    project_name="voestalpine"
                                />
                            </>
                        ) : (
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <div>
                                    <h1 className="uppercase font-bold font-heading">No tienes permiso para ver otro perfil hasta cerrar sesión</h1>
                                </div>
                            </div>
                        )
                    ) : (
                        <AnimatePresence>
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <motion.img
                                    initial={{ rotateY: 0 }}
                                    animate={{ rotateY: 360 }}
                                    transition={{ duration: 2, repeat: Infinity, type: "tween", repeatDelay: 0 }}
                                    exit={{ opacity: 0 }}
                                    src={logo}
                                    alt="logo makinn"
                                    className="mx-auto mb-5"
                                    width={150} height={150}
                                />
                                <p className="uppercase text-lg">Cargando</p>
                            </div>
                        </AnimatePresence>
                    )
                ) : (
                    loader && user ? (
                        <>
                            <HeaderLoginVoestalpine
                                user={user}
                            />
                            <MainCointainerVoestalpine
                                loginFlag={loginFlag}
                                user={user}
                                device={device}
                                typeOfDevice={typeOfDevice}
                                project_name="voestalpine"
                            />
                        </>
                    ) : (
                        <AnimatePresence>
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <motion.img
                                    initial={{ rotateY: 0 }}
                                    animate={{ rotateY: 360 }}
                                    transition={{ duration: 2, repeat: Infinity, type: "tween", repeatDelay: 0 }}
                                    exit={{ opacity: 0 }}
                                    src={logo}
                                    alt="logo makinn"
                                    className="mx-auto mb-5"
                                    width={150} height={150}
                                />
                                <p className="uppercase text-lg">Cargando</p>
                            </div>
                        </AnimatePresence>
                    )
                )
                }

            </>
        )
    }
    if (user?.template === "carnot") {
        return (
            <>
                {loginFlag ? (
                    loader && user ? (
                        authID === user.authID ? (
                            <>
                                <HeaderCarnot
                                    props={{
                                        userId,
                                        projectId
                                    }}
                                />
                                <MainCointainerCarnot
                                    loginFlag={loginFlag}
                                    user={user}
                                    device={device}
                                    typeOfDevice={typeOfDevice}
                                />
                            </>
                        ) : (
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <div>
                                    <h1 className="uppercase font-bold font-heading">No tienes permiso para ver otro perfil hasta cerrar sesión</h1>
                                </div>
                            </div>
                        )
                    ) : (
                        <AnimatePresence>
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <motion.img
                                    initial={{ rotateY: 0 }}
                                    animate={{ rotateY: 360 }}
                                    transition={{ duration: 2, repeat: Infinity, type: "tween", repeatDelay: 0 }}
                                    exit={{ opacity: 0 }}
                                    src={logo}
                                    alt="logo makinn"
                                    className="mx-auto mb-5"
                                    width={150} height={150}
                                />
                                <p className="uppercase text-lg">Cargando</p>
                            </div>
                        </AnimatePresence>
                    )
                ) : (
                    loader && user ? (
                        <>
                            <HeaderLoginCarnot
                                user={user}
                            />
                            <MainCointainerCarnot
                                loginFlag={loginFlag}
                                user={user}
                                device={device}
                                typeOfDevice={typeOfDevice}
                            />
                        </>
                    ) : (
                        <AnimatePresence>
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <motion.img
                                    initial={{ rotateY: 0 }}
                                    animate={{ rotateY: 360 }}
                                    transition={{ duration: 2, repeat: Infinity, type: "tween", repeatDelay: 0 }}
                                    exit={{ opacity: 0 }}
                                    src={logo}
                                    alt="logo makinn"
                                    className="mx-auto mb-5"
                                    width={150} height={150}
                                />
                                <p className="uppercase text-lg">Cargando</p>
                            </div>
                        </AnimatePresence>
                    )
                )
                }

            </>
        )
    }
    if (user?.template === "procaps") {
        return (
            <>
                {loginFlag ? (
                    loader && user ? (
                        authID === user.authID ? (
                            <>
                                <HeaderProcaps
                                    props={{
                                        userId,
                                        projectId
                                    }}
                                />
                                <MainCointainerProcaps
                                    loginFlag={loginFlag}
                                    user={user}
                                    device={device}
                                    typeOfDevice={typeOfDevice}
                                />
                            </>
                        ) : (
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <div>
                                    <h1 className="uppercase font-bold font-heading">No tienes permiso para ver otro perfil hasta cerrar sesión</h1>
                                </div>
                            </div>
                        )
                    ) : (
                        <AnimatePresence>
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <motion.img
                                    initial={{ rotateY: 0 }}
                                    animate={{ rotateY: 360 }}
                                    transition={{ duration: 2, repeat: Infinity, type: "tween", repeatDelay: 0 }}
                                    exit={{ opacity: 0 }}
                                    src={logo}
                                    alt="logo makinn"
                                    className="mx-auto mb-5"
                                    width={150} height={150}
                                />
                                <p className="uppercase text-lg">Cargando</p>
                            </div>
                        </AnimatePresence>
                    )
                ) : (
                    loader && user ? (
                        <>
                            <HeaderLoginProcaps
                                user={user}
                            />
                            <MainCointainerProcaps
                                loginFlag={loginFlag}
                                user={user}
                                device={device}
                                typeOfDevice={typeOfDevice}
                            />
                        </>
                    ) : (
                        <AnimatePresence>
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <motion.img
                                    initial={{ rotateY: 0 }}
                                    animate={{ rotateY: 360 }}
                                    transition={{ duration: 2, repeat: Infinity, type: "tween", repeatDelay: 0 }}
                                    exit={{ opacity: 0 }}
                                    src={logo}
                                    alt="logo makinn"
                                    className="mx-auto mb-5"
                                    width={150} height={150}
                                />
                                <p className="uppercase text-lg">Cargando</p>
                            </div>
                        </AnimatePresence>
                    )
                )
                }

            </>
        )
    }
    if (user?.template === "ollamani") {
        return (
            <>
                {loginFlag ? (
                    loader && user ? (
                        authID === user.authID ? (
                            <>
                                <HeaderOllamani
                                    props={{
                                        userId,
                                        projectId
                                    }}
                                />
                                <MainCointainerOllamani
                                    loginFlag={loginFlag}
                                    user={user}
                                    device={device}
                                    typeOfDevice={typeOfDevice}
                                />
                            </>
                        ) : (
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <div>
                                    <h1 className="uppercase font-bold font-heading">No tienes permiso para ver otro perfil hasta cerrar sesión</h1>
                                </div>
                            </div>
                        )
                    ) : (
                        <AnimatePresence>
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <motion.img
                                    initial={{ rotateY: 0 }}
                                    animate={{ rotateY: 360 }}
                                    transition={{ duration: 2, repeat: Infinity, type: "tween", repeatDelay: 0 }}
                                    exit={{ opacity: 0 }}
                                    src={logo}
                                    alt="logo makinn"
                                    className="mx-auto mb-5"
                                    width={150} height={150}
                                />
                                <p className="uppercase text-lg">Cargando</p>
                            </div>
                        </AnimatePresence>
                    )
                ) : (
                    loader && user ? (
                        <>
                            <HeaderLoginOllamani
                                user={user}
                            />
                            <MainCointainerOllamani
                                loginFlag={loginFlag}
                                user={user}
                                device={device}
                                typeOfDevice={typeOfDevice}
                            />
                        </>
                    ) : (
                        <AnimatePresence>
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <motion.img
                                    initial={{ rotateY: 0 }}
                                    animate={{ rotateY: 360 }}
                                    transition={{ duration: 2, repeat: Infinity, type: "tween", repeatDelay: 0 }}
                                    exit={{ opacity: 0 }}
                                    src={logo}
                                    alt="logo makinn"
                                    className="mx-auto mb-5"
                                    width={150} height={150}
                                />
                                <p className="uppercase text-lg">Cargando</p>
                            </div>
                        </AnimatePresence>
                    )
                )
                }

            </>
        )
    }
    if (user?.template === "oxxo") {
        return (
            <>
                {loginFlag ? (
                    loader && user ? (
                        authID === user.authID ? (
                            <>
                                <HeaderOxxo
                                    props={{
                                        userId,
                                        projectId
                                    }}
                                />
                                <MainCointainerOxxo
                                    loginFlag={loginFlag}
                                    user={user}
                                    device={device}
                                    typeOfDevice={typeOfDevice}
                                />
                            </>
                        ) : (
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <div>
                                    <h1 className="uppercase font-bold font-heading">No tienes permiso para ver otro perfil hasta cerrar sesión</h1>
                                </div>
                            </div>
                        )
                    ) : (
                        <AnimatePresence>
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <motion.img
                                    initial={{ rotateY: 0 }}
                                    animate={{ rotateY: 360 }}
                                    transition={{ duration: 2, repeat: Infinity, type: "tween", repeatDelay: 0 }}
                                    exit={{ opacity: 0 }}
                                    src={logo}
                                    alt="logo makinn"
                                    className="mx-auto mb-5"
                                    width={150} height={150}
                                />
                                <p className="uppercase text-lg">Cargando</p>
                            </div>
                        </AnimatePresence>
                    )
                ) : (
                    loader && user ? (
                        <>
                            <HeaderLoginOxxo
                                user={user}
                            />
                            <MainCointainerOxxo
                                loginFlag={loginFlag}
                                user={user}
                                device={device}
                                typeOfDevice={typeOfDevice}
                            />
                        </>
                    ) : (
                        <AnimatePresence>
                            <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
                                <motion.img
                                    initial={{ rotateY: 0 }}
                                    animate={{ rotateY: 360 }}
                                    transition={{ duration: 2, repeat: Infinity, type: "tween", repeatDelay: 0 }}
                                    exit={{ opacity: 0 }}
                                    src={logo}
                                    alt="logo makinn"
                                    className="mx-auto mb-5"
                                    width={150} height={150}
                                />
                                <p className="uppercase text-lg">Cargando</p>
                            </div>
                        </AnimatePresence>
                    )
                )
                }

            </>
        )
    }
}
export default Dashboard;